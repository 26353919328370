import { Link as LinkBase } from '@atoms/link';
import styled from 'styled-components';

import { Column as ColumnBase, Row as RowBase } from '@atoms/Grid';

const List = styled.ul`
  padding: 0;
  list-style: none;
`;

const ListTitle = styled.h2`
  ${props => props.theme.typo.bodyM};
  ${props => props.theme.fontWeight.demi};
  color: ${props => props.theme.color.g500};
  margin-bottom: 15px;
`;

const ListItem = styled.li`
  margin-bottom: 15px;
`;

const Row = styled(RowBase)`
  @media ${props => props.theme.query.mobile} {
    margin: 0;
  }
`;

const Link = styled(LinkBase)`
  ${props => props.theme.typo.bodyS};
  color: ${props => props.theme.color.g500};

  &:hover {
    color: ${props => props.theme.color.p300};
  }
`;

const Column = styled(ColumnBase)`
  margin-bottom: 50px;

  @media ${props => props.theme.query.desktop} {
    margin-bottom: 100px;
  }
`;

export { List, ListTitle, ListItem, Row, Link, Column };
