import { useTranslation } from '@infra/i18n';
import PropTypes from 'prop-types';
import React from 'react';

import {
  Column,
  Link,
  List,
  ListItem,
  ListTitle,
  Row,
} from './styledComponents';

export const SitemapLinks = props => {
  const { columns, columnProps } = props;
  const { t } = useTranslation();

  return (
    <Row>
      {columns.map(col => (
        <Column {...columnProps} key={col.header}>
          <ListTitle>{t(col.header)}</ListTitle>
          <List>
            {col.links.map(link => (
              <ListItem key={link.label}>
                <Link type={link.type} to={link.url}>
                  {t(link.label)}
                </Link>
              </ListItem>
            ))}
          </List>
        </Column>
      ))}
    </Row>
  );
};

export const sitemapColumnsType = PropTypes.arrayOf(
  PropTypes.shape({
    header: PropTypes.string,
    links: PropTypes.arrayOf(
      PropTypes.shape({ url: PropTypes.string, label: PropTypes.string })
    ),
  })
);

SitemapLinks.propTypes = {
  columns: sitemapColumnsType.isRequired,
  columnProps: PropTypes.shape({
    sm: PropTypes.number,
    md: PropTypes.number,
    lg: PropTypes.number,
  }),
};

SitemapLinks.defaultProps = {
  columnProps: { sm: 6 },
};
