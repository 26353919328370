import { groupBy } from '@utils/group-by';

const getLandingPageColumns = (dynamicLandingPages = [], linkLocation = '') => {
  const mappedPages = dynamicLandingPages
    .filter(
      page =>
        page.pageType !== 'None' && page.linkLocation.includes(linkLocation)
    )
    .map(val => ({
      url: `/${val.pageURL}`,
      label: val.pageLinkName,
      id: val.pageLinkName,
      category: val.pageTypeLabel,
    }));

  const pageCategories = groupBy(mappedPages, 'category');
  const columns = Object.keys(pageCategories).map(categoryTitle => ({
    key: categoryTitle,
    lists: [
      {
        header: categoryTitle,
        links: pageCategories[categoryTitle],
      },
    ],
  }));

  return columns;
};

export { getLandingPageColumns };
