import { ROUTES } from '@infra/routes';

export const sitemapColumns = [
  {
    header: 'footer.quote',
    links: [
      {
        url: ROUTES.QUOTE_FORM,
        label: 'footer.getAQuote',
      },
      {
        url: ROUTES.QUOTES_PAGE,
        label: 'footer.viewQuotes',
      },
    ],
  },
  {
    header: 'footer.bookShipment',
    links: [
      {
        url: ROUTES.QUOTES_PAGE,
        label: 'footer.bookShipment',
      },
      {
        url: ROUTES.BOOKINGS_PAGE,
        label: 'footer.viewBookings',
      },
    ],
  },
  {
    header: 'footer.support',
    links: [
      {
        url: ROUTES.DOCUMENTS_LIST,
        label: 'footer.documentList',
      },
      {
        url: ROUTES.CO2FACTS,
        label: 'sitemap.co2Facts',
      },
    ],
  },
  {
    header: 'footer.track',
    links: [
      {
        url: ROUTES.SHIPMENTS_PAGE,
        label: 'footer.trackShipments',
      },
    ],
  },
  {
    header: 'footer.resources',
    links: [
      {
        url: ROUTES.KNOWLEDGE_SERIES,
        label: 'footer.knowledgeSeries',
      },
      {
        url: ROUTES.VIDEOS,
        label: 'footer.videos',
      },
      {
        url: ROUTES.SHIP_FOR_SUCCESS,
        label: 'footer.shipForSuccess',
      },
    ],
  },
  {
    header: 'footer.videos',
    links: [
      {
        url: 'https://www.youtube.com/embed/d1KILW8mHbI?rel=0',
        label: 'videos.introShipa.title',
      },
      {
        url: 'https://www.youtube.com/embed/YiWqHuK639k?rel=0',
        label: 'videos.howToRegister.title',
      },
      {
        url: 'https://www.youtube.com/embed/9khSoWSef0w?rel=0',
        label: 'videos.requestAQuote.title',
      },
      {
        url: 'https://www.youtube.com/embed/Z4kqlwagrcQ?rel=0',
        label: 'videos.bookAShipment.title',
      },
      {
        url: 'https://www.youtube.com/embed/KUSJyy7kRYk?rel=0',
        label: 'videos.arrangePayment.title',
      },
      {
        url: 'https://www.youtube.com/embed/BJj6pwcjBYM?rel=0',
        label: 'videos.trackShipment.title',
      },
      {
        url: 'https://www.youtube.com/embed/p6kmiRWMCU0?rel=0',
        label: 'videos.futureForShipaFreight.title',
      },
      {
        url: 'https://www.youtube.com/embed/VSLVnVgiD9s?rel=0',
        label: 'videos.impactOfSMEs.title',
      },
      {
        url: 'https://www.youtube.com/embed/GAfr9rK8p5I?rel=0',
        label: 'videos.fba.title',
      },
      {
        url: 'https://www.youtube.com/embed/hMN-tHCS1Ro?rel=0',
        label: 'videos.shippingForSMEs.title',
      },
      {
        url: 'https://www.youtube.com/embed/UJb7DZdLYmc?rel=0',
        label: 'videos.shipaFreightCanHelp.title',
      },
    ],
  },
  {
    header: 'sitemap.otherLinks',
    links: [
      {
        url: ROUTES.PRIVACY_POLICY,
        label: 'sitemap.privacyPolicy',
      },
      {
        url: ROUTES.TERMS_AND_CONDITIONS,
        label: 'sitemap.termsAndConditions',
      },
    ],
  },
];
