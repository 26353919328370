import { useTranslation } from '@infra/i18n';
import { graphql, useStaticQuery } from 'gatsby';
import get from 'lodash.get';
import React from 'react';

import { HeroPanel } from '@organisms/HeroPanel';

const HeroSection = () => {
  const { t } = useTranslation('sitemap.heroPanel');

  const image = useStaticQuery(graphql`
    query {
      desktop: file(
        relativePath: { eq: "sitemap/default-static-page-header.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, width: 1920)
        }
      }
    }
  `);

  const backgroundImage = get(image, 'desktop.childImageSharp.gatsbyImageData');

  return <HeroPanel backgroundImage={backgroundImage} title={t('title')} />;
};

export { HeroSection };
