import styled from 'styled-components';

const Section = styled.section`
  width: 100%;
  background-color: ${props => props.theme.color.white};
  padding: 50px 15px 0 15px;
`;

const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  max-width: 1200px;
`;

export { Section, Container };
