import { LayoutDefault } from '@features/layouts/layout-default';
import { SEO } from '@features/seo';
import { sitemapColumns } from '@features/sitemap/constants';
import { HeroSection } from '@features/sitemap/hero-section';
import { SitemapLinks } from '@features/sitemap/sitemap-links';
import { Container, Section } from '@features/sitemap/styled-components';
import { getLandingPageColumns } from '@features/sitemap/utils';
import { gatsbyPageProps } from '@infra/gatsby/types';
import { selectByLanguage } from '@infra/i18n';
import { graphql, useStaticQuery } from 'gatsby';
import get from 'lodash.get';
import React from 'react';

const SitemapPage = props => {
  const {
    pageContext: { locale },
    location,
  } = props;

  const data = useStaticQuery(graphql`
    query {
      allStrapiKnowledgeseriesarticle {
        nodes {
          label: cardTitle
          url: articleURL
          language
        }
      }
      allStrapiDynamiclandingpage {
        nodes {
          pageLinkName
          pageURL
          headerTitle
          pageTypeLabel
          linkLocation
          language
        }
      }
      ebooks: allStrapiPage(
        filter: { PageMeta: { subpath: { eq: "/e-book" } } }
      ) {
        nodes {
          pageUrl
          PageMeta {
            metaTitle
          }
        }
      }
    }
  `);

  const articles = selectByLanguage({
    items: get(data, 'allStrapiKnowledgeseriesarticle.nodes', []),
    language: locale,
  });

  const knowledgeSeriesArticle = {
    header: 'footer.knowledgeSeries',
    links: articles.map(({ url, label }) => {
      return {
        url: `/knowledge-series/${url}`,
        label,
      };
    }),
  };

  const dynamicLandingPage = [];

  const landingPages = selectByLanguage({
    items: get(data, 'allStrapiDynamiclandingpage.nodes', []),
    language: locale,
  });

  getLandingPageColumns(landingPages).forEach(({ lists }) =>
    lists.forEach(list => dynamicLandingPage.push(list))
  );

  const ebooks = {
    header: 'eBook.title',
    links: get(data, 'ebooks.nodes', []).map(ebook => ({
      label: get(ebook, 'PageMeta.metaTitle'),
      url: `/e-book/${get(ebook, 'pageUrl')}`,
    })),
  };

  return (
    <LayoutDefault locale={locale} location={location}>
      <SEO location={location} lang={locale} />
      <HeroSection />
      <Section>
        <Container>
          <SitemapLinks
            columns={[
              ...sitemapColumns,
              knowledgeSeriesArticle,
              ...dynamicLandingPage,
              ebooks,
            ]}
            columnProps={{ sm: 6, md: 4, lg: 3 }}
          />
        </Container>
      </Section>
    </LayoutDefault>
  );
};

SitemapPage.propTypes = {
  ...gatsbyPageProps,
};

export { SitemapPage };
export default SitemapPage;
